import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import { GolDateFormat } from '../../../../plugins/common/date.js'
import { Field, Popup, DatetimePicker, Icon, List, Image } from 'vant'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Loading: Loading,
        Share: Share,
        Navbar: Navbar,
        [Field.name]: Field,
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [Icon.name]: Icon,
        [List.name]: List,
        [Image.name]: Image
    },
    data () {
        return {
            finishedShow: false,
            loadingShow: false,
            showTime: false,
            merchantCode: '',
            condition: { time: '' },
            recordList: [],
            page: { current: 0, size: 10, last: 0 },
            minDate: new Date(2022, 0, 1),
            maxDate: new Date()
        }
    },
    mounted () {
        var query = this.$route.query
        this.merchantCode = query.merchantCode
        this.$refs.share.default('main')
    },
    methods: {
        loadTaskList () {
            if (this.page.last >= this.page.current) {
                this.retrieveRecordList()
            } else {
                this.finishedShow = true
            }
        },
        async retrieveRecordList () {
            this.loadingShow = true
            this.page.current = this.page.current + 1
            var pd = { merchantCode: this.merchantCode, current: this.page.current, size: this.page.size }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWalletRecordList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.recordList = this.recordList.concat(res.data)
                this.page.last = res.page.last
            }
            this.loadingShow = false
        },
        onTimeConfirm (val) {
            var date = GolDateFormat(val)
            this.condition.time = date.substring(0, 7)
            this.showTime = false
        },
        onTimeCancel (val) {
            this.condition.time = ''
            this.showTime = false
        },
        back () {
            this.$router.go(-1)
        }
    }
}
