module.exports = {
    GolDayCal (param) {
        var start = new Date()
        var end = new Date(start.getTime() + (param * 1000 * 3600 * 24))
        var year = end.getFullYear()
        var month = end.getMonth() + 1
        var date = end.getDate()
        var res = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
        return res
    },
    GolDateFormat (param) {
        var year = param.getFullYear()
        var month = param.getMonth() + 1
        var date = param.getDate()
        var res = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
        return res
    },
    GolGetDayDate (day) {
        var target = new Date()
        var targetDateMil = target.getTime() + 1000 * 60 * 60 * 24 * day
        target.setTime(targetDateMil)
        var year = target.getFullYear()
        var month = target.getMonth() + 1
        var date = target.getDate()
        var res = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
        return res
    },
    GolDateDay (start, end) {
        var sd = Date.parse(start)
        var ed = Date.parse(end)
        var day = ed - sd
        var res = parseInt(day / (1000 * 60 * 60 * 24))
        return res
    }
}
